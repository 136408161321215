.dotted-underline {
    text-decoration: underline dotted; }

h2 {
    @extend .dotted-underline; }

.name-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        margin-top: auto;
        margin-bottom: auto;
        text-align: center; } }

.logo-container {
    margin-right: 1em; }

nav {
    justify-content: center; }
